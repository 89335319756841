import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FilterContainer = ({
  children,
  handleClearSelection,
  handleClick,
  showClearButton,
  isLoading = false,
  refName,
  subtext,
  title,
  titleSubtext,
}) => (
  <div className="filter">
    <div className="filter__header">
      <button
        className="ch-btn filter__header-back"
        onClick={handleClick}
        type="button"
      >
        Back
      </button>
      <div className="ch-pa--2 ch-display--flex ch-flex-flow--row-wrap ch-justify-content--between ch-align-items--center">
        <h3
          className={classNames('ch-fs--4 ch-mb--0', {
            'ch-width--6': showClearButton,
          })}
        >
          {title}
          {titleSubtext && <span className="ch-fw--400"> {titleSubtext}</span>}
        </h3>
        <div>
          {subtext && (
            <p className="ch-mt--2 ch-mb--0 ch-fs--3 ch-color--grey-6">
              {subtext}
            </p>
          )}
        </div>
        {showClearButton && (
          <button
            className="ch-btn ch-btn--sm ch-color--ac-red ch-flex--none"
            onClick={() => handleClearSelection()}
            type="button"
          >
            Clear selection
          </button>
        )}
      </div>
    </div>
    <div className="filter__body" ref={refName}>
      {children}
    </div>
    <div className="filter__footer">
      <button
        className={`ch-btn ch-btn--block ch-btn--primary ${
          isLoading ? 'ch-btn--loading' : ''
        }`}
        onClick={handleClick}
        type="button"
      >
        {isLoading ? '' : 'Done'}
      </button>
    </div>
  </div>
);

export default FilterContainer;

FilterContainer.propTypes = {
  children: PropTypes.node,
  handleClearSelection: PropTypes.func,
  handleClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  refName: PropTypes.object,
  showClearButton: PropTypes.bool,
  subtext: PropTypes.string,
  title: PropTypes.string,
  titleSubtext: PropTypes.string,
};
